/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
// import HeartIcon from '@scandipwa/scandipwa/src/component/HeartIcon';
// import CloseIcon from '@scandipwa/scandipwa/src/component/CloseIcon';
import { CategoryPageLayout } from '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.config';
import { Suspense } from 'react';

import AddToCart from 'Component/AddToCart';
import AniTextIconBtn from 'Component/AniTextIconBtn/AniTextIconBtn.component';
import { ProductCardComponentProps } from 'Component/ProductCard/ProductCard.type';
import ProductCompareButton from 'Component/ProductCompareButton';
import ProductCompareButtonAnimation from 'Component/ProductCompareButtonAnimation/ProductCompareButtonAnimation.container';
import ProductWishListButtonAnimation from 'Component/ProductWishlistButtonAnimation/ProductWishlistButtonAnimation.container';
import QuickAddIcon from 'Component/QuickAddIcon/QuickAddIcon.component';
import SelectedOptionLabel from 'Component/SelectedOptionLabel';
import TickIcon from 'Component/TickIcon/TickIcon.component';
import TrustPilotReviews from 'Component/TrustPilotReviews';
import Html from 'SourceComponent/Html';
import Image from 'SourceComponent/Image';
import { ImageRatio } from 'SourceComponent/Image/Image.type';
import Link from 'SourceComponent/Link';
import { ProductType } from 'SourceComponent/Product/Product.config';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import { ReactElement } from 'SourceType/Common.type';
import { getStoreState } from 'SourceUtil/Store';

import { ProductComponent, ProductConfigurableAttributes, QuickAddModal } from '../Product/Product.component';

import './ProductCard.style';

export interface ProductCardComponentState {
    layout: CategoryPageLayout;
}

/** @namespace Inov8/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    static defaultProps: Partial<ProductCardComponentProps> = {
        ...ProductComponent.defaultProps,
        thumbnail: '',
        linkTo: '',
        children: null,
        isLoading: false,
        mix: {},
        renderContent: null,
        hideWishlistButton: false,
        hideCompareButton: false,
        layout: window.innerWidth <= 768 ? CategoryPageLayout.LIST : CategoryPageLayout.GRID,
        productThumbnail: '',
        isProductLinks: false,
    };

    state = {
        isQuickAddModalOpen: false,
        forceUpdateFlag: false,
        imageSrc: '',
    };

    openQuickAddModal = () => {
        this.setState({ isQuickAddModalOpen: true });
    };

    closeQuickAddModal = () => {
        this.setState({ isQuickAddModalOpen: false });
    };

    __construct(props: any) {
        super.__construct(props);
    }

    renderQuickViewBtn = () => (
        <AniTextIconBtn
          block="quickAdd-btn"
          text="QUICK ADD"
          icon={ <QuickAddIcon /> }
          onClick={ this.openQuickAddModal }
        />
    );

    renderQuickAddModal(): ReactElement {
        const {
            setActiveProduct,
            parameters,
            product,
            product: { type_id: type, variants = [] },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
            isPlp,
            layout,
            showSelectOptionsNotification,
            addToCart,
            updateSelectedValues,
            quantity,
            getActiveProduct,
        } = this.props;

        if (
            type !== ProductType.CONFIGURABLE
            || !Object.keys(this.getConfigurableAttributes()).length
            || !inStock
        ) {
            return null;
        }

        return (
            <div>
                <Suspense fallback={ null }>
                    <QuickAddModal
                      numberOfPlaceholders={ [2, 4] }
                      updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                      addToCartTriggeredWithError={ addToCartTriggeredWithError }
                      mix={ { block: this.className, elem: 'Attributes' } }
                      parameters={ parameters }
                      variants={ variants }
                      updateConfigurableVariant={ setActiveProduct }
                      configurable_options={ this.getConfigurableAttributes() }
                      isContentExpanded
                      inStock={ inStock }
                      showProductAttributeAsLink={ false }
                      isPlp={ isPlp }
                      layout={ layout }
                      isOpen={ this.state.isQuickAddModalOpen }
                      onClose={ this.closeQuickAddModal }
                      product={ product }
                      showSelectOptionsNotification={ showSelectOptionsNotification }
                      requiresConfiguration={ this.requiresConfiguration() }
                      addToCart={ addToCart }
                      getActiveProduct={ getActiveProduct() }
                      quantity={ quantity }
                      updateSelectedValues={ updateSelectedValues }
                      // eslint-disable-next-line react/jsx-no-bind
                    />
                </Suspense>
            </div>
        );
    }

    renderShortDescription() {
        const { product: { short_description }, layout, isProductLinks } = this.props;
        const { html }: { html?: string } = short_description || {};

        if (!html || isProductLinks) {
            return null;
        }

        const isMobile = window.innerWidth < 768;
        const isListView = layout === CategoryPageLayout.LIST;
        const isGridView = layout === CategoryPageLayout.GRID;

        if (isListView || (isGridView && !isMobile)) {
            return (
                <div block="short-description-container">
                    <Html content={ html } />
                </div>
            );
        }

        return null;
    }

    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  blockColor="secondary"
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
        );
    }

    renderCompareButton(): ReactElement {
        const { product: { id } } = this.props;

        if (!id) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <ProductCompareButton
                  productId={ id }
                  blockColor="secondary"
                  mix={ {
                      elem: 'ProductCompareButton',
                      mods: { isGrey: true },
                  } }
                />
            </Suspense>
        );
    }

    renderCompareButtonAnimation(): ReactElement {
        const { product: { id } } = this.props;

        if (!id) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <ProductCompareButtonAnimation
                  productId={ id }
                  mix={ {
                      block: this.className,
                      elem: 'ProductCompareButton',
                      mods: { isGrey: true },
                  } }
                />
            </Suspense>
        );
    }

    renderCompareAnimation() {
        const {
            compareBtn,
        } = this.props;

        return (
            <>
                { compareBtn && this.renderCompareButtonAnimation() }
            </>
        );
    }

    renderCompareStatic() {
        const {
            compareBtn,
        } = this.props;

        return (
            <div block="ProductCard" elem="ProductActions">
                { compareBtn && this.renderProductCompareButton() }
            </div>
        );
    }

    renderHeartStatic() {
        const {
            wishlistBtn,
        } = this.props;

        return (
            <div block="ProductCard" elem="ProductActions">
                { wishlistBtn && this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderWishlistButtonAnimation(): ReactElement {
        const { magentoProduct, isWishlistEnabled } = this.props;

        if (magentoProduct.length === 0 || !isWishlistEnabled) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <ProductWishListButtonAnimation
                  magentoProduct={ magentoProduct }
                  mix={ {
                      block: this.className,
                      elem: 'WishListButton',
                  } }
                />
            </Suspense>
        );
    }

    renderProductCardWishlistButtonAnimation(): ReactElement {
        const { hideWishlistButton, isWishlistEnabled } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return this.renderWishlistButtonAnimation();
    }

    renderHeartAnimation(): ReactElement {
        const {
            wishlistBtn,
        } = this.props;

        return (
            <div block="ProductCard" elem="ProductActions">
                { wishlistBtn && this.renderProductCardWishlistButtonAnimation() }
            </div>
        );
    }

    renderProductActions(): ReactElement {
        const {
            wishlistBtn,
        } = this.props;

        return (
            <div block="ProductCard" elem="ProductActions">
                { wishlistBtn && this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderReviews(): ReactElement {
        const { product: { sku } } = this.props;

        // eslint-disable-next-line no-constant-condition
        if (sku !== '1') {
            return null;
        }

        return (
            <div
              block="ProductCard"
            >
                <TrustPilotReviews sku={ sku } noClick={ false } productSchema={ false } />
            </div>
        );
    }

    renderListViewActions(): ReactElement {
        return (
            <div block="ProductCard" elem="Actions">
                <div>{ this.renderQuickAddModal() }</div>
                <div block="ActionButton" elem="QuickAdd">{ this.renderQuickViewBtn() }</div>
                { this.renderCompareButton() }
            </div>
        );
    }

    renderPicture(mix = {}) {
        const {
            product: { id, name, variants },
            productThumbnail, thumbnail, onLoad,
        } = this.props;

        const { ConfigReducer: { secure_base_media_url } = {} } = getStoreState();

        const { colours } = this.props.parameters;
        let imageSrc = productThumbnail || thumbnail;
        let foundMatch = false;

        if (colours) {
            variants?.forEach((variant) => {
                if (!foundMatch) {
                    const variantColour = variant.attributes.colours.attribute_value;
                    // console.log(`Checking if ${colours} equals ${variantColour}`);

                    if (colours === variantColour) {
                        imageSrc = `${secure_base_media_url}catalog/product/${variant.attributes.image.attribute_value}?width=446&height=446`;
                        foundMatch = true;
                        this.setState({ imageSrc });
                    }
                }
            });
        }

        return (
            <>
                <Image
                  imageRef={ this.imageRef }
                  src={ imageSrc }
                  alt={ name }
                  ratio={ ImageRatio.IMG_SQUARE }
                  mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                  isPlaceholder={ !id }
                  onImageLoad={ onLoad }
                />
            </>
        );
    }

    renderListViewFeatures(): ReactElement {
        const { product: { attributes } } = this.props;

        if (!attributes) {
            return <div block="ProductCard" elem="FeaturesList" />;
        }

        if (!attributes.lister_bullet_points) {
            return <div block="ProductCard" elem="FeaturesList" />;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let listerBulletPoints;
        try {
            listerBulletPoints = JSON.parse(attributes.lister_bullet_points.attribute_value);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Failed to parse product lister bullet points JSON:', this.props.product.sku);

            return <div block="ProductCard" elem="FeaturesList" />;
        }

        if (!listerBulletPoints) {
            return <div block="ProductCard" elem="FeaturesList" />;
        }

        return (
            <div block="ProductCard" elem="FeaturesList">
                { listerBulletPoints.map((bulletPoint: { id: string; description: string }) => (
                    <div block="ProductCard" elem="FeaturesListItem" key={ bulletPoint.id }>
                        <TickIcon />
                        <span>{ bulletPoint.description }</span>
                    </div>
                )) }
            </div>
        );
    }

    renderQuickViewAction(): ReactElement {
        const {
            addToCartBtn,
        } = this.props;

        return (
            <div block="ProductCard" elem="QuickViewFooter">
                { addToCartBtn && this.renderAddToCart() }
            </div>
        );
    }

    renderConfigurableOptions(): ReactElement {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = [] },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
            layout,
            isPlp,
            isProductLinks,
        } = this.props;

        if (
            type !== ProductType.CONFIGURABLE
            || !Object.keys(this.getConfigurableAttributes()).length
            || !inStock
        ) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="AttributesWrapper"
            >
                <Suspense fallback={ null }>
                    <ProductConfigurableAttributes
                      // eslint-disable-next-line no-magic-numbers
                      numberOfPlaceholders={ [2, 4] }
                      updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                      addToCartTriggeredWithError={ addToCartTriggeredWithError }
                      mix={ { block: this.className, elem: 'Attributes' } }
                      parameters={ parameters }
                      variants={ variants }
                      updateConfigurableVariant={ setActiveProduct }
                      configurable_options={ this.getConfigurableAttributes() }
                      isContentExpanded
                      inStock={ inStock }
                      showProductAttributeAsLink={ false }
                      layout={ layout }
                      isPlp={ isPlp || isProductLinks }
                    />

                </Suspense>
            </div>
        );
    }

    selectedLabel() {
        const {
            product: { configurable_options },
            parameters,
        } = this.props;

        // Example usage of configurable_options
        let selectedOptionLabel = '';

        if (configurable_options && configurable_options.colours && configurable_options.colours.attribute_options) {
            const attributeOptions = configurable_options.colours.attribute_options;
            const selectedOption = parameters?.colours?.toString();
            selectedOptionLabel = selectedOption ? attributeOptions[selectedOption]?.label : '';
        }

        return selectedOptionLabel;
    }

    renderCardContent(): ReactElement {
        const {
            renderContent,
            product: { name },
            product,
            swatches,
            selectedLabel,
            compareBtn,
            shortDescription,
            device: { isMobile },
        } = this.props;

        const { ConfigReducer: { code } = {} } = getStoreState();

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard">
                { this.renderCardLinkWrapper((
                    <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                        <div block="ProductCard" elem="FigureReview">
                            <div block="plp-icons">
                                { compareBtn && (
                                    !isMobile && this.renderCompareAnimation()
                                ) }
                                { this.renderHeartAnimation() }
                            </div>

                            { this.renderPicture() }

                            { swatches && (
                                <div block="swatchesReserved">
                                    { this.renderConfigurableOptions() }
                                </div>
                            ) }

                            <div block="product-name">{ this.renderName(false) }</div>

                            <div block="ProductCard" elem="Content">
                                { this.renderBrand() }
                            </div>

                            { code !== 'row' && (
                                <div block="ProductCard-badge-price-container">
                                    { this.renderPrice() }
                                </div>
                            ) }

                            <TrustPilotReviews product={ product } />

                            { selectedLabel && (
                            <SelectedOptionLabel label={ this.selectedLabel() } />
                            ) }

                            { shortDescription && (
                                <div>{ this.renderShortDescription() }</div>
                            ) }
                        </div>
                    </div>
                )) }
                { /* { code !== 'row' && ( */ }
                    <>
                <div>{ this.renderQuickAddModal() }</div>
                { product.stock_status !== 'OUT_OF_STOCK' ? (
                    <div>{ this.renderQuickViewBtn() }</div>
                ) : (
                    <div block="AniTextIconBtn OutOfStock">
                        <div block="ani-text-icon-btn quickAdd-btn">
                            <div block="text">OUT OF STOCK</div>
                        </div>
                    </div>
                ) }
                    </>
                { /* ) } */ }
            </div>
        );
    }

    renderCardListContent(): ReactElement {
        const {
            children,
            layout,
            renderContent,
            swatches,
            device: { isMobile },
            product,
            linkTo,
        } = this.props;

        const { ConfigReducer: { code } = {} } = getStoreState();

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="list-container">
                { this.renderCardLinkWrapper(
                    <div block="ProductCard" elem="Link">
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                <div block="plp-icons">
                                    { this.renderHeartAnimation() }
                                </div>
                                { this.renderPicture() }

                            </figure>
                            { isMobile && swatches && (
                                <div block="ProductCard" elem="FigureSwatches">
                                    { this.renderConfigurableOptions() }
                                </div>
                            ) }
                        </div>
                    </div>,
                ) }

                <div block="details-container">
                <Link
                  to={ linkTo }
                  onClick={ this.handleLinkClick }
                >
                    <div block="ProductCard" elem="MainInfo">
                        { this.renderBrand() }
                        { this.renderMainDetails() }
                    </div>

                    { code !== 'row' && (
                        <div block="ProductCard-badge-price-container">
                            { this.renderPrice() }
                        </div>
                    ) }
                    <TrustPilotReviews product={ product } />
                    <SelectedOptionLabel label={ this.selectedLabel() } />
                </Link>
                    { !isMobile && swatches && (
                        <div block="swatchesReserved">
                            { this.renderConfigurableOptions() }
                        </div>
                    ) }
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>

                    <div block="ProductCard" elem="Content" mods={ { layout } }>
                        { this.renderListViewFeatures() }
                    </div>

                    { code !== 'row' && (<div>{ this.renderQuickAddModal() }</div>) }
                    <div block="list-btn-container">
                        { /* { code !== 'row' && ( */ }
                            <>
                            { this.renderQuickViewBtn() }
                            <div>{ this.renderQuickAddModal() }</div>
                            </>
                        { /* ) } */ }
                        { this.renderCompareButton() }
                    </div>
                </div>
            </div>
        );
    }
}
export default ProductCardComponent;
